<template>
  <main style="scroll-snap-type: y mandatory;">
    <div style="height: 70px;"></div>
    <section id="nav">
      <nav-component></nav-component>
    </section>
    <section id="hero" style="scroll-snap-align: start;">
      <!-- <img src="@/assets/img/mobile hero.webp"  class="d-sm-none"/> -->
      <video autoplay muted class="d-none d-sm-inline">
        <source src="@/assets/vid/hero-animate.webm" type="video/webm">
        This browser doesn't support video tag.
      </video>



      <div
        class="container px-4 py-5 text-center justify-content-center position-absolute bottom-0 start-50 translate-middle-x">

        <img class=" img-fluid" src="@/assets/img/yellow-logo.webp" alt="">

        <div class="my-2">
          <h3 class="d-none d-sm-inline" style="font-family: 'Noto Sans Sinhala';">හර්ෂ බමුණුආරච්චි රචනා කරන</h3>
        </div>
        <div class="col-lg-6 mx-auto">
          <p class="mb-1" style="font-family: 'Noto Sans Sinhala';"> <span class="d-none d-sm-inline">ආදරය, කරුණාව, දයාව
              උතුරා යන, සැබෑම ලෙන්ගතුකම පිරුණු, <br> මිහිපිට </span>දෙවියන් වන් මිනිසුන්ගේ
            ජීවිත කතාව</p>
          <div class="d-grid gap-2 d-sm-flex justify-content-sm-center p-1">
            <!-- <button type="button" class="btn btn-primary btn-lg px-4 gap-3">Primary button</button> -->
            <a type="button" class="btn btn-outline-primary btn-lg px-4" href="#bookLibrary" role="a">Book Library</a>
          </div>
        </div>
      </div>

    </section>

<!--    <section>-->
<!--      <div class="album py-5 bg-body-tertiary">-->
<!--        <div class="container">-->

<!--          <div class="row d-flex justify-content-center row-cols-1 row-cols-sm-2 row-cols-md-2 g-3">-->
<!--            <div class="col">-->
<!--              <div class="card shadow-sm">-->
<!--                <img src="@/assets/img/ex-2.webp" width="100%"-->
<!--                  alt="Colombo International Book Fair - Sudu minissu stall">-->

<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->

    <section>

      <div class="latest">
        <a href="/books/vilapaya">
          <img src="@/assets/img/Vilapaya.webp" width="100%"
               alt="Vilapaya book">
        </a>
        <a href="/books/dam-senevi">
          <img src="@/assets/img/DamSenevi.webp" width="100%"
               alt="Dam Senevi book">
        </a>
        <a href="/books/sudu-minissu-6">
          <img src="@/assets/img/SM6.webp" width="100%"
               alt="Sudu Minissu 6 book">
        </a>
      </div>

    </section>

    <!-- START THE FEATURETTES -->
    <section id="Gallery" class="mt-4 pb-5 gap">

      <div class="container">
        
        <div id="bookLibrary" class="row text-center">
          <h1 class="row-title pt-2">Book Library</h1>
        </div>
        <hr class="featurette-divider">

        <div class="row featurette" data-aos="fade-up" data-aos-duration="1000">
          <div class="col-md-6 order-md-2 p-0">
            <a href="/books/sudu-minissu-1">
              <img src="@/assets/img/SM01.webp" width="100%">
            </a>


          </div>
          <div class="col-md-6 order-md-1 p-4">
            <!-- <h2 class="featurette-heading">සුදු මිනිස්සු 1</h2> -->
            <a href="/books/sudu-minissu-1" class="featurette-heading text-col text-decoration-none golden">සුදු මිනිස්සු 1</a>
            <p class="featurette-body fs-6"> &nbsp;&nbsp;&nbsp;"දුවේ, මේ විශ්වයේ තියෙන ලොකු ම රහස... දෙන්න දෙන්න තමයි
              ලැබෙන්නේ. දුව අනිත් අයට ආදරය, සෙනෙහස, කරුණාව දෙන නිසා දුවට ඒවා උතුරන්න ලැබෙනවා...
              <!-- යන යන තැන පිරිස දුව ව ආරක්ෂා කරන්න හදනවා මිසක් කරදරයක් කරන්න මාත්‍රයක්වත් හිතන්නෙ නෑ.
              ඒකෙන් ගෑනු දරුවෙකුට ලොකු ආරක්ෂාවක් සැලසෙනවා..." -->
            </p>

            <a href="/books/sudu-minissu-1" class="btn btn-primary">READ MORE</a>
          </div>

        </div>


        <div class="row featurette" data-aos="fade-up" data-aos-duration="1000">
          <div class="col-md-6 order-md-1 p-0">
            <a href="/books/sudu-minissu-2">
              <img src="@/assets/img/SM02.webp" width="100%">
            </a>
            

          </div>
          <div class="col-md-6 order-md-2 p-4">
            <!-- <h2 class="featurette-heading">සුදු මිනිස්සු 2</h2> -->
            <a href="/books/sudu-minissu-2" class="featurette-heading text-col text-decoration-none golden">සුදු මිනිස්සු 2</a>
            <p class="featurette-body fs-6">&nbsp;&nbsp;&nbsp; "වෙදමහත්තයා, දැන් ගොඩක් දුර ඔය දාන පෙට්ටිය හිස තියාගෙන
              ආවානේ. අපිට දෙන්න අපි
              මෙතනින් එහාට අරගෙන යන්නම්...
              <!-- <br>
              &nbsp;&nbsp;&nbsp;"කරුණාදාස, ඔහේලා මගේ සේවකයෝ වෙන්න පුළුවන්, නමුත් දානයක් දෙන්න යනකොට ඒ දානයෙන් පින්
              ලැබෙන්නේ ඒ
              දානයට මහා ගරු ගුණ හිතකින් සම්බන්ධවන අයට විතරයි. සේවකයෝ ලවා දන් දුන්නහම ඒකෙන් තමන්ටත් පිනක් ලැබෙනවා නම්,
              තමන්ට හැදිච්ච ලෙඩේ හොඳ වෙන්න සේවකයෝ බෙහෙත් බිව්වහමත් හරිනේ..." -->
            </p>
            <a href="/books/sudu-minissu-2" class="btn btn-primary">READ MORE</a>

          </div>

        </div>

        <div class="row featurette" data-aos="fade-up" data-aos-duration="1000">
          <div class="col-md-6 order-md-2  p-0">
            <a href="/books/sudu-minissu-3">
              <img src="@/assets/img/SM03.webp" width="100%">
            </a>
            

          </div>
          <div class="col-md-6 order-md-1  p-4">
            <!-- <h2 class="featurette-heading">සුදු මිනිස්සු 3</h2> -->
            <a href="/books/sudu-minissu-3" class="featurette-heading text-col text-decoration-none golden">සුදු මිනිස්සු 3</a>
            <p class="featurette-body fs-6">&nbsp;&nbsp;&nbsp;"අපේ ගෙදර පවුලේ සාමාජිකයෙක්ගේ උපන්දිනය තියෙන්නේ හෙට නම්,
              අද රෑ ඒ කෙනා හැරෙන්න අනිත් අය ඔක්කොම සිල් සමාදන් වෙලා, රෑ පුරාවට ම සීලාදී ගුණධර්මයන් පුරලා, පහුවදා උදේට...
              <!-- ඒ හැමෝම එකතු වෙලා, ඒ උපන්දිනය ලබන කෙනාට දීර්ඝායුෂ පතලා ආශීර්වාද කරනවා. ඒ වගේ ම දහමින් පෝෂණය වෙලා යහපත් ජීවිතයක් ගත
              කරන්න, ඒ වගේම යහපත් කල්‍යාණ මිත්‍රයන් ලැබෙන්න කියලා මහා ආශිර්වාදයක් සිද්ධ කරනවා. ඒක එදා ඉඳලම අපේ
              අප්පච්චිලගේ පරම්පරාවෙන් ආපු එකක්..." -->
            </p>
            <a href="/books/sudu-minissu-3" class="btn btn-primary">READ MORE</a>
          </div>

        </div>
        <div class="row featurette" data-aos="fade-up" data-aos-duration="1000">
          <div class="col-md-6 order-md-1  p-0">
            <a href="/books/sudu-minissu-4">
              <img src="@/assets/img/SM04.webp" width="100%">
            </a>
            

          </div>
          <div class="col-md-6 order-md-2  p-4">
            <!-- <h2 class="featurette-heading">සුදු මිනිස්සු 4</h2> -->
            <a href="/books/sudu-minissu-4" class="featurette-heading text-col text-decoration-none golden">සුදු මිනිස්සු 4</a>
            <p class="featurette-body fs-6">&nbsp;&nbsp;&nbsp;"කවුරු හරි තමන්ට උදව්වක් කළහම, ඒ උදව්
              කළ කෙනා ගැන ලොකු විශ්වාසයක් ඇතිවෙනව නේ. අන්න ඒ වෙලාවට ඒ කෙනා කියන දේ අහන්න ගොඩක් දුරට හිත දෙනවා...
              <!-- ඒක නිසා පුතාල කාට හරි උදව්වක් කරපු වෙලාවට, ඒ කෙනා ව දස අකුසලයෙන් ගලවලා සීහෙළ ගති තියෙන කෙනෙක් කරන්න උත්සාහ කරන්න.
              අද අපේ දෝණි එහෙම එකක් නේ කළේ..." -->
            </p>

            <a href="/books/sudu-minissu-4" class="btn btn-primary">READ MORE</a>
            <!--  <div class="row " style="background-color:">              <button class="button-52" role="button">Button 52</button></div>-->
          </div>

        </div>
        <div class="row featurette" data-aos="fade-up" data-aos-duration="1000">
          <div class="col-md-6 order-md-2  p-0">
            <a href="/books/sudu-minissu-5">
              <img src="@/assets/img/SM05.webp" width="100%">
            </a>
            

          </div>
          <div class="col-md-6 order-md-1  p-4">
            <!-- <h2 class="featurette-heading">සුදු මිනිස්සු 5</h2> -->
            <a href="/books/sudu-minissu-5" class="featurette-heading text-col text-decoration-none golden">සුදු මිනිස්සු 5</a>
            <p class="featurette-body fs-6">&nbsp;&nbsp;&nbsp;"අද ඉන්න හුඟ දෙනෙක්ගෙන් ඇහුවොත් සෞභාග්‍ය කියන්නෙ මොකද්ද
              කියලා, ගත්ත කටට ම කියන්නෙ සල්ලි මිල මුදල් ටිකක් යහමින් තියෙන එක කියලා. ඒත් පුතේ...
              <!-- සෞභාග්‍ය කියන්නෙ ඒකට
              නෙමෙයි. සෞභාග්‍ය කියන්නෙ හිතේ සහනයෙන් සතුටින් ජීවත්වෙන පරිසරයට. ඒකට තමන්ගෙ අතේ තියෙන සල්ලි ගාණ බලපාන්නෙ නෑ
              පුතේ... සල්ලි කෝටියක් තිබුණත් වැඩක් නෑනෙ හිතේ සතුටක් නැත්නම්. හරි පුතා මට කියන්න, අඩි සීයක් විතර ගැඹුරු
              රත්තරං ආකරයක වැටිලා ගොඩ එන්න බැරුව ඉන්න මිනිහා, සෞභාග්‍ය සම්පන්න ද කියලා?" -->
            </p>
            <a href="/books/sudu-minissu-5" class="btn btn-primary">READ MORE</a>
          </div>

        </div>

        <div class="row featurette" data-aos="fade-up" data-aos-duration="1000">
          <div class="col-md-6 order-md-1  p-0">
            <a href="/books/sudu-minissu-6">
              <img src="@/assets/img/SM06.webp" width="100%">
            </a>
            
          </div>
          <div class="col-md-6 order-md-2  p-4">
            <a href="/books/sudu-minissu-6" class="featurette-heading text-col text-decoration-none golden">සුදු මිනිස්සු 6</a>
            <p class="featurette-body fs-6">&nbsp;&nbsp;&nbsp;"කසාදයක් කරගන්න කලින් තව කෙනෙක් එක්ක සතුටින් ජීවත්වෙන්නෙ
              කොහොමද කියලා දැනගන්න ඕනෙනෙ. එහෙම නැති වුණහම වෙන්නෙ කඩිමුඩියේ විවාහ වෙලා විවේකීව පසුතැවෙන්න තමයි...
             
            </p>

            <a href="/books/sudu-minissu-6" class="btn btn-primary">READ MORE</a>
          </div>

        </div>

        <div class="row featurette" data-aos="fade-up" data-aos-duration="1000">
          <div class="col-md-6 order-md-2  p-0">
            <a href="/books/sudu-minissu-7">
              <img src="@/assets/img/SM07.webp" width="100%">
            </a>
            

          </div>
          <div class="col-md-6 order-md-1  p-4">
            <!-- <h2 class="featurette-heading">සුදු මිනිස්සු 5</h2> -->
            <a href="/books/sudu-minissu-7" class="featurette-heading text-col text-decoration-none golden">සුදු මිනිස්සු 7</a>
            <p class="featurette-body fs-6">&nbsp;&nbsp;&nbsp;"දරුවට තමන්ගෙ දුක කියාගන්න කෙනෙක් නැතිවෙනකොට, ආශ්‍රය කරන්න කෙනෙක් නැතිවෙනකොට, එයාලා ටීවී එකට, කම්පියුටරයට පුරුදුවෙනවා. ඊට පස්සේ තමන්ගෙ හිතේ තියෙන කාන්සිය...
             
            </p>
            <a href="/books/sudu-minissu-7" class="btn btn-primary">READ MORE</a>
          </div>

        </div>

        <!-- /END THE FEATURETTES -->
      </div>
    </section>
    <section id="Other" class="py-5" style="background-color: black; color: white;">
      <div class="container">

        <div class="row text-center">
          <h1 class="row-title">Other Books</h1>
        </div>
        <hr class="featurette-divider">
        <div class="row featurette" data-aos="fade-up" data-aos-duration="1000">
          <div class="col-md-6 order-md-2 p-0">
            <a href="/books/vilapaya">
              <img src="@/assets/img/VilapayaB.webp" width="100%">
            </a>


          </div>
          <div class="col-md-6 order-md-1 p-4">
            <!-- <h2 class="featurette-heading">විලාපය</h2> -->
            <a href="/books/vilapaya" class="featurette-heading text-col text-decoration-none golden">විලාපය</a>
            <p class="featurette-body fs-6">“ඔව් නංගි... ඒක හරියට වෝ ෆ්‍රන්ට් එක වගේ. නංගිලා දන්නවනෙ, යුධ පෙරමුණේ
              ඉස්සරහින්
              ම ඉන්න සොල්දාදුවන්ට, මොන ම හේතුවකින්වත් ආපහු හැරිලා එන්න බෑ කියලා...
             
            </p>
            <!--            <p class="text-end featurette-body fs-6 "> - අප්පච්චි -</p>-->
            <a href="/books/vilapaya" class="btn btn-primary">READ MORE</a>
          </div>

        </div>
        <div class="row featurette" data-aos="fade-up" data-aos-duration="1000">
          <div class="col-md-6 order-md-1 p-0">
            <a href="/books/dam-senevi">
              <img src="@/assets/img/DamSenavi.webp" width="100%">
            </a>


          </div>
          <div class="col-md-6 order-md-2 p-4">
            <!-- <h2 class="featurette-heading">දම් සෙනෙවි</h2> -->
            <a href="/books/dam-senevi" class="featurette-heading text-col text-decoration-none golden">දම් සෙනෙවි</a>
            <p class="featurette-body fs-6">“එකල පරසිඳු සාරද නම් වූ මම, ජනුත්තම අනෝමදස්සී නරශ්‍රේෂ්ඨයන් වහන්සේගෙන්
              ලත් නියත විවරණයෙන් ඇරඹ, සම්බුදු අගසව් පදවිය පතා දිවිහිමියෙන් පාරමී දම් පිරුවෙමි...
             
            </p>
            <!--            <p class="text-end featurette-body fs-6 "> - අප්පච්චි -</p>-->
            <a href="/books/dam-senevi" class="btn btn-primary">READ MORE</a>
          </div>

        </div>
        <div class="row featurette" data-aos="fade-up" data-aos-duration="1000">
          <div class="col-md-6  order-md-2  p-0">
            <a href="/books/minis-sunakhaya">
              <img src="@/assets/img/MinisSunakaya.png" width="100%">
            </a>
            

          </div>
          <div class="col-md-6 order-md-1  p-4">
            <!-- <h2 class="featurette-heading">මිනිස් සුනඛයා</h2> -->
            <a href="/books/minis-sunakhaya" class="featurette-heading text-decoration-none golden">මිනිස්
              සුනඛයා</a>
            <p class="featurette-body fs-6">සත්බස බැහැර කොට, මානයෙන් උඩඟුව, මිත්‍යාව වපුරමින්, සමාජය කෙලෙසූ දුර්ජනයෙකුගේ
              ඛේදණීය
              ඉරණමක නූතන විවරණය...</p>
            <a href="/books/minis-sunakhaya" class="btn btn-primary">READ MORE</a>
          </div>

        </div>
        <div class="row featurette" data-aos="fade-up" data-aos-duration="1000">
          <div class="col-md-6 order-md-1  p-0">
            <a href="/books/maw-senehasa">
              <img src="@/assets/img/MawuSenahasa.png" width="100%">
            </a>
            

          </div>
          <div class="col-md-6 order-md-2  p-4">
            <!-- <h2 class="featurette-heading">මව් සෙනෙහස</h2> -->
            <a href="/books/maw-senehasa" class="featurette-heading text-decoration-none golden">මව් සෙනෙහස</a>
            <p class="featurette-body fs-6">‘අම්මා' උල්පතයි - ජීවිතය නදියක් නම්<br>
              ගිරි දුර්ග අතරින් ගලාගෙන - දුර ඇදෙන සයුර ම හොයාගෙන...

              <br /><br>

            </p>
            <a href="/books/maw-senehasa" class="btn btn-primary">READ MORE</a>
            <!--  <div class="row " style="background-color:">              <button class="button-52" role="button">Button 52</button></div>-->
          </div>

        </div>
        <div class="row featurette" data-aos="fade-up" data-aos-duration="1000">
          <div class="col-md-6  order-md-2  p-0">
            <a href="/books/sarwajana-washiya">
              <img src="@/assets/img/SarwajanaWashiya.png" width="100%">
            </a>
            


          </div>
          <div class="col-md-6 order-md-1  p-4">
            <!-- <h2 class="featurette-heading">සර්වජන වශිය</h2> -->

            <a href="/books/sarwajana-washiya" class="featurette-heading text-decoration-none golden">සර්වජන
              වශිය</a>

            <p class="featurette-body fs-6">ගොඩක් මිනිස්සුන්ට මේ ලෝකෙ කරන්න ලේසි ම දේ පවනෙ. ‘සල්ලි තියෙන මිනිස්සු ලෝභයෝ’
              කිය කියා, මහා කම්මැලි මිනිස්සු කකුල් දෙකත් උඩ දාගෙන මැච් බල බලා ඉන්නකොට...
              <!-- , ඒ අයට කන්න බොන්නත් නැතිවෙන එක
              අහන්නත් දෙයක් යෑ? ඒකයි මම කියන්නෙ, සල්ලි නෙමෙයි ප්‍රශ්නෙ, සාරධර්ම උගන්නන්නෙ නැති එකයි ප්‍රශ්නෙ කියලා. මොකද
              අපිට සල්ලි තිබුණා කියලා, කිසි ම කෙනෙකුට අපේ අතින් හානියක් වෙලා තියෙනවා යෑ, යහපතක් මිසක්. -->
            </p>
            <a href="/books/sarwajana-washiya" class="btn btn-primary">READ MORE</a>
          </div>

        </div>
        <div class="row featurette" data-aos="fade-up" data-aos-duration="1000">
          <div class="col-md-6 order-md-1 p-0">
            <a href="/books/sapalath-mawkusa">
              <img src="@/assets/img/SapalathMawuKusa.png" width="100%">
            </a>


          </div>
          <div class="col-md-6 order-md-2 p-4">
            <!-- <h2 class="featurette-heading">සාපලත් මව්කුස</h2> -->
            <a href="/books/sapalath-mawkusa" class="featurette-heading text-decoration-none golden">සාපලත් මව්කුස</a>
            <p class="featurette-body fs-6"> &nbsp;&nbsp;&nbsp;"සමාජයේ ඉන්න අසත්පුරුෂ මිනිස්සු, ඒ අයගේ ඔළුවලට විකාර
              ඇතුළු කරලනෙ තියෙන්නෙත්. ඒක නිසා මස්කට්ටට පොරකන බලු රංචුව වගේ, උදේ ඉඳලා රෑ වෙනකල් රුපියල් සතවලට පොරකනවා...
             
            </p>
            <!--            <p class="text-end featurette-body fs-6 "> - අප්පච්චි -</p>-->
            <a href="/books/sapalath-mawkusa" class="btn btn-primary">READ MORE</a>
          </div>

        </div>
      


        <!-- /END THE FEATURETTES -->
      </div>

    </section> <!-- books shops-->
    <!-- <section id="author" style="background-color: rgb(0, 0, 0); color: rgb(255, 255, 255);">
      <div class="container">
        <div class="row featurette" data-aos="fade-up" data-aos-duration="1000">
            <div class="col-md-6  order-md-2  p-0">
              <a target="_blank" href="https://harshabamunuarachchi.lk/" >
                <img src="@/assets/img/HarshaB.jpg">
              </a>
              
            </div>
            <div class="col-md-6 order-md-1  p-4">
              <a target="_blank" href="https://harshabamunuarachchi.lk/" class="featurette-heading text-white text-decoration-none">Author</a>

              <p class="featurette-body fs-6">A true humanist at heart, Harsha believes in the inherent worth and potential of every individual. His commitment to leading by example has earned him an outstanding reputation as a mentor and friend to many. His unwavering positivity and enthusiasm for life inspires others to strive for their own goals and dreams.
          
              </p>
              <a target="_blank" href="https://harshabamunuarachchi.lk/" class="btn btn-primary">READ MORE</a>
            </div>

          </div>
      </div>
    </section> -->
    <section id="map" style="background-color: rgb(0, 0, 0); color: rgb(255, 255, 255);">

      <!-- <section id="Other" class="py-5" style="background-color: black; color: white;">
        <div class="container">
  
          <div class="row text-center">
            <h1 class="row-title">Other Books</h1>
          </div>
          <hr class="featurette-divider"> -->



      <div class="container">
        <div class="row text-center mb-4">

          <h1 class="row-title">Sudu minissu available at</h1>
        </div>
        <!-- <div class="row text-center">
          <h1 class="row-title">Other Books</h1>
        </div> -->
        <hr class="featurette-divider mb-4">
        <div class="row justify-content-center">
          <div class="col-9 col-sm-10 col-md-4 col-lg-3 mb-2">
            <div class="p-3 bg-body rounded" style="width: 100%">
              <a href="shops"> <img src="@/assets/img/SuduMinissuPrakashana.png" class="card-img-top"
                  alt="Sudu Minissu Prakashana" /></a>
            </div>
          </div>
          <div class="col-9 col-sm-10 col-md-4 col-lg-3 mb-2 ">
            <div class=" p-3 bg-body rounded" style="width: 100%">
              <a href="shops"> <img src="@/assets/img/sarasavi-logo.webp" class="card-img-top" alt="Sarasavi"></a>
            </div>
            <div style="width: 100%">
            </div>
          </div>
          <div class="col-9 col-sm-10 col-md-4 col-lg-3 mb-2">
            <div class="p-3 bg-body rounded" style="width: 100%">
              <a href="shops#dayawansa"> <img src="@/assets/img/dayawansa-logo.webp" class="card-img-top"
                  alt="Dayawansa" /></a>
            </div>
          </div>
          <div class="col-9 col-sm-10 col-md-4 col-lg-3 mb-2">
            <div class="p-3 bg-body rounded " style="width:  100%">
              <a href="shops#sooriya"> <img src="@/assets/img/surya-logo.webp" class="card-img-top"
                  alt="sooriya" /></a>

            </div>
          </div>
          
        </div>
        <div class="row justify-content-center">

          <div class="col-9 col-sm-10 col-md-4 col-lg-3 mb-2 ">
            <div class=" p-3 bg-body rounded" style="width: 100%">
              <a href="shops#samudra"> <img src="@/assets/img/samudra-logo.png" class="card-img-top" alt="samudra"></a>
            </div>
            <div style="width: 100%">
            </div>
          </div>
          <div class="col-9 col-sm-10 col-md-4 col-lg-3 mb-2">
            <div class="p-3 bg-body rounded" style="width: 100%">
              <a href="shops#Expographic"> <img src="@/assets/img/Expographic.png" class="card-img-top"
                  alt="Expographic" /></a>
            </div>
          </div>
          <div class="col-9 col-sm-10 col-md-4 col-lg-3 mb-2">
            <div class="p-3 bg-body rounded" style="width: 100%">
              <a href="shops#minsara"> <img src="@/assets/img/minsara-logo.png" class="card-img-top"
                  alt="minsara" /></a>
            </div>
          </div>
          <div class="col-9 col-sm-10 col-md-4 col-lg-3 mb-2">
            <div class="p-3 bg-body rounded" style="width: 100%">
              <a href="shops#oneero"> <img src="@/assets/img/oneero-logo.png" class="card-img-top"
                  alt="oneero" /></a>
            </div>
          </div>
          
        </div>
      </div>
      <!-- <hr class="featurette-divider"> -->
      <div class="row featurette  mx-0">
        <div class="col p-0">


          <img src="@/assets/img/map.webp" width="100%">

        </div>

      </div>

      <!-- /END THE FEATURETTES -->
      <!-- </div> -->
    </section>


    <div class="bg-light border-top mt-4">
      <div class="container">
        <footer id="footer" class="d-flex flex-wrap justify-content-between align-items-center py-3  mx-4">
          <div class="col-md-4 d-flex align-items-center">
            <!-- <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                <svg class="bi" width="30" height="24">
                  <use xlink:href="#bootstrap"></use>
                </svg>
              </a> -->
            <span class="text-muted">© 2024 Sudu Minissu</span>
          </div>

          <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li class="ms-3"><a class="text-muted" href="https://fb.me/suduminissu.lk/"><i
                  class="fab fa-facebook-f"></i></a></li>
            <li class="ms-3"><a class="text-muted" href="https://youtube.com/channel/UCz16kZZfsq5p8irh_fAZrfw"><i
                  class="fab fa-youtube"></i></a></li>
            <li class="ms-3"><a class="text-muted" href="whatsapp://send?phone=94761367821&text=සුදු%2C%20මිනිස්සු"><i
                  class="fab fa-whatsapp"></i></a></li>
            <li class="ms-3"><a class="text-muted" href="https://m.me/suduminissu.lk"><i
                  class="fab fa-facebook-messenger"></i></a></li>
            <li class="ms-3"><a class="text-muted" href="mailto:info@suduminissu.lk"><i class="fas fa-envelope"></i></a>
            </li>
          </ul>


        </footer>
      </div>
    </div>


  </main>
</template>
<script>
import NavComponent from './Nav.vue'
export default {
  name: "HomePage",
  components: {
    NavComponent
  }
};
</script>
<script setup>
import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
})
</script>
<style>
@charset "utf-8";

@font-face {
  font-family: 'isiwara';
  src: url('@/assets/fonts/UN-Isiwara.woff');
}

@font-face {
  font-family: 'gemunu';
  src: url('@/assets/fonts/UN-Gemunu.woff');
}

@font-face {
  font-family: "Basuru";
  src: url('@/assets/fonts/UN-Basuru.otf');
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

#nr {
  width: 100%;
  height: 100vh;
}

#hero {
  width: 100%;
  /* background-image: url("@/assets/img/hero animate.gif"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: calc(100vh - 70px);

  overflow: hidden;
}

@media only screen and (max-width: 576px) {
  #hero {
    width: 100%;
    background-image: url("@/assets/img/mobile\ hero.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: calc(100vh - 70px);

    overflow: hidden;
  }
}

#hero video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

ol li {
  position: -webkit-sticky;
  /* position: sticky; */
  top: 0;
  position: sticky;
}

#new-vid {
  width: 100%;
  /* background-image: url("@/assets/img/hero animate.gif"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;

  overflow: hidden;
}

#new-vid video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.text-col {
  color: #2c3e50;

}

.sec-title {
  align-content: center;
}

.sudu-books {
  height: 70vh;
}

.gap:target {
  padding-top: 90px;
  /* = to height of navbar */
  margin-top: -90px;
}

.header {
  min-height: 69px;
}

.wa {

  background-color: #25D366;
  border: 0px;
}

.msg {

  background-color: #006AFF;
  border: 0px;

}

.wa:hover {

  background-color: #22BA59;
  border: 0px;
}

.msg:hover {

  background-color: #0060E6;
  border: 0px;

}

.row-title {
  font-family: raleway, gemunu, sans-serif;
  text-transform: uppercase;
}

.card-title {
  font-family: 'Noto Sans Sinhala', sans-serif;
}

.featurette-heading {
  margin-top: 0;
  font-size: 35pt;
  font-family: "Basuru", sans-serif;
}

.lead {
  font-family: 'Noto Sans Sinhala', sans-serif;
}

.featurette-body {
  font-family: 'Noto Sans Sinhala', sans-serif;
}

.card-text {
  font-family: 'Noto Sans Sinhala', sans-serif;
  font-size: smaller;
}

.nav-link {
  color: #000;
}

.navbar .nav-item .dropdown-menu {
  display: none;
}

.navbar .nav-item:hover .dropdown-menu {
  display: block;
}

.navbar .nav-item .dropdown-menu {
  margin-top: 0;
}

/*
.navbar {
    background: none;
   background: rgba(0,0,0,0.4);
  }
.navbar-collapse {
  background: none;
  background: rgba(0,0,0,0.4);
}*/
.avatar {
  border-radius: 50%;
  width: 100%;
}

.navbar {
  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;

  /* the rest of your styling */
}

.navbar-light .navbar-nav .nav-link {
  color: black;
}

.gradient-custom {
  /* fallback for old browsers */
  background: #ffffff;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, rgba(231, 231, 231, 0.5), rgba(194, 194, 194, 0.5));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, rgba(231, 231, 231, 0.5), rgba(194, 194, 194, 0.5))
}

.depth {
  display: block;
  border-radius: 25px solid rgb(49, 49, 49);
  background: linear-gradient(rgb(240, 240, 240), rgb(255, 255, 255));
  transition: all 0.3s ease-out;
  padding: 5px;
  color: rgb(0, 81, 255);
}

.depth:focus {
  outline: none;
  background-position: 0 -1.7em;
}

.box-body {
  background-color: rgb(12 12 12);
  border-radius: 5px;
}

.button-52 {
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-52:after {
  content: "";
  background-color: #ffe54c;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.button-52:hover:after {
  top: 0px;
  left: 0px;
}

@media (min-width: 768px) {
  .button-52 {
    padding: 13px 50px 13px;
  }
}

.nav-phone {
  margin-bottom: 0px;

}

.golden{
  color: #FFC000;
}

.latest {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>